import Http from '@/ship/Http';

export const updateChartStatusToComplete = (id: number) => Http.get(`/project-management/charts/${id}/complete`);

export const updateChartStatusToStopped = (id: number) => Http.get(`/project-management/charts/${id}/stop`);

export const updateChartStatusToApproved = (id: number) => Http.get(`/project-management/charts/${id}/approve`);

export const updateChartStatusToCancelled = (id: number) => Http.get(`/project-management/charts/${id}/cancel`);

export const updateChartStatusToPlanned = (id: number) => Http.get(`/project-management/charts/${id}/to-planned`);
