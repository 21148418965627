import { Vue } from 'vue-property-decorator';

export function CheckBeforeExecution(
    shouldExecute: (ctx: Vue) => boolean, // `ctx` - это `this` компонента
    errorHandler: (ctx: Vue) => void,
) {
    return function (_target: any, _key: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            const self = this as Vue; // Привязываем `this` к компоненту

            if (shouldExecute(self)) {
                return originalMethod.apply(self, args);
            } else {
                errorHandler(self);
            }
        };

        return descriptor;
    };
}
